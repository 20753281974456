@import "./variable.scss";

.header {
  height: 9vh;
  position: sticky;
  top: 0;
  z-index: 99;
}

.object-fit {
  img {
    object-fit: cover;
  }
}

.soldout-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60px;
  border: 1px solid #fff;
  position: absolute;
  border-radius: 30px;
  background-color: red;
  color: #fff;
  font-weight: bold;
  font-size: 6px;
  transform: rotate(-45deg) translateY(-28px);
}
.soldout-container > * {
  opacity: 0.8;
}
.soldout-container > div:nth-child(2) {
  background-color: red;
}
.soldout-container .sold-out {
  font-size: 1.5em;
  font-weight: bold;
}

.input-group .btn {
  background-image: linear-gradient(-180deg, #56b559, #088389) !important;
}

.sidebar {
  position: sticky;
  top: 0;
  height: 100vh;
  z-index: 100;
  background: #fff;
  overflow-y: auto;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important;
}

.sidebar-btn-wrapper {
  background-color: #000 !important;
}

.sidebar-btn span {
  color: $secondary !important;
  font-weight: bold;
}

.pro-sidebar > .pro-sidebar-inner {
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important;
}

.ps-sidebar-root {
  .ps-submenu-content.ps-open {
    background-color: #f7f8f9;
  }

  .ps-submenu-expand-icon {
    left: -55px;
    position: relative;
    visibility: visible;
    opacity: 1;
    transition: opacity 1.5s;
  }

  &.ps-collapsed {
    .ps-submenu-expand-icon {
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.1s;
    }
  }

  .ps-submenu-root.ps-open {
    background: #f7f8f9;

    .ps-menu-button {
      color: #666;

      &.ps-active {
        color: #000;
      }
    }
  }
}

.cursor {
  cursor: pointer;
}

//Text
.xs-label {
  font-size: 13px;
}

.xxs-label {
  font-size: 10px;
}

.bg-image-white {
  background-image: linear-gradient(to right, #fff, #fff) !important;
  border: 1px solid $primary !important;
}

.bg-primary-grad {
  background-color: $primary;
  // background-image: linear-gradient(to right, #da8cff, #9a55ff);
  border-width: 0;
}

//Button
.btn {
  color: #fff;
  background-color: $primary;
  border-width: 0;
  font-size: 14px;

  &:hover {
    color: #fff;
  }

  &:focus {
    color: #fff;
    box-shadow: none;
  }
}

.btn-wh {
  height: 3rem;
}

// input[type="text"]:disabled,
// input[type="number"]:disabled,
// select:disabled {
//   background: #fafafa !important;
// }

//Table
.table > :not(:first-child) {
  border-top: 1px solid $success;
}

.table tr {
  height: 3.5rem;
}

// th {
//   text-align: center;
// }

th,
td {
  font-size: 0.9rem;
  vertical-align: middle;
  background-color: $primary;
}

th {
  background-color: $primary;
  font-weight: bold;
}

tr {
  height: 1rem;
}

.report-table {
  tr {
    height: 1rem;
  }
}

.border-none {
  border: none !important;
}

.thumb-image {
  height: 40px;
  width: 40px;
  object-fit: cover;
}

//Input
.form-control:focus {
  box-shadow: none;
}

.form-label,
.form-check-label {
  color: $text-muted-color;
}

.form-check-input {
  &:focus {
    box-shadow: none;
  }
}

.form-check-input:checked {
  border: none;
}

.input-form-control {
  .form-control {
    height: 2.5rem;
  }
}

input[type="text"]:disabled {
  background: #fff;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #eee;
  opacity: 1;
}

.search-input {
  input {
    height: 3rem;
  }
}

.first-select-group {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.second-select-group {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

//Layout
.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.flex-center {
  display: flex;
  justify-content: center;
}

.bg-card {
  background-color: #fff;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  padding: 1.5rem;
  border-radius: $border-radius;
}

.bg-color {
  background-color: $card-bg;
}

//Accordion
.accordion-button {
  padding: 0.8rem;

  &:focus {
    box-shadow: none;
  }
}

//Dropdown
.avatar-dropdown {
  cursor: pointer;
  font-size: 14px;
  line-height: 1.2;

  .dropdown {
    height: 5rem;
    display: flex;
    align-items: center;

    .dropdown-toggle::after {
      display: none !important;
    }
  }

  .header-avatar {
    img {
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  img {
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 50%;
  }

  .dropdown-menu {
    padding: 0 !important;
    margin-top: 0 !important;
    box-shadow: 0rem 1rem 1rem rgba(#000, 0.15);
    border: none;

    .dropdown-item {
      padding: 0.8rem 2rem 0.8rem 1rem;
      font-size: 0.9rem;
      color: $text-muted-color;
    }

    .title {
      font-size: 0.9rem;
      color: $text-muted-color;
      margin-bottom: 0.5rem;
    }

    .last-item {
      border-bottom-left-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
    }
  }
}

.accordion-button:not(.collapsed) {
  color: rgb(37, 33, 33);
  background-color: #f8f9fa;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}

//Profile
.avatar {
  height: 150px;
  width: 150px;
  background: #ddd;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  img {
    height: 150px;
  }
}

.btn-upload {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  padding: 2px 0 0 8px;
  cursor: pointer;
  position: absolute;
  right: 5px;
  bottom: 10px;
  color: #fff;
  background-color: $primary;
}

//Remove Arrows/Spinners
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

// /* Firefox */
// input[type="number"] {
//   -moz-appearance: textfield;
// }

.config-card {
  &:hover {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    transition: box-shadow 400ms;
  }
}

// pagination
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0 !important;

  .page-item {
    .page-link:focus {
      box-shadow: none !important;
    }
  }

  .page-item.active .page-link {
    // background-image: linear-gradient(-180deg, #56b559, #088389) !important;
    background: $primary;
    color: $secondary;
  }
}
.page-item:first-child .page-link {
  border-top-left-radius: $border-radius;
  border-bottom-left-radius: $border-radius;
}

.pagination-last-item {
  .page-link {
    border-top-right-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}

.jewellery-type-card {
  width: 100%;
  height: 6rem;
  border: 1px solid #ced4d9;
  margin-bottom: 24px;
  border-radius: 0.25rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5px;
  align-items: center;

  .jewellery-type-label {
    font-size: 12px;
  }

  .jewellery-type-label-small {
    font-size: 8.25px;
  }

  .jewellery-type-img {
    width: 40%;
    height: 35px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .fill-white {
    filter: invert(100%) sepia(95%) saturate(0%) hue-rotate(111deg)
      brightness(104%) contrast(100%);
  }

  // .fill-muted {
  //   filter: invert(60%) sepia(20%) saturate(1403%) hue-rotate(62deg)
  //     brightness(94%) contrast(83%);
  // }
}

.pos-detail-image {
  width: 100%;
  height: 200px !important;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.thumbnail-list-image {
  width: 60px;
  height: 60px !important;

  img {
    // width: 100%;
    width: 60px;
    height: 100%;
    border-radius: 0.25rem !important;
    object-fit: cover;
  }
}

.thumbnail-list-image-sm {
  width: 40px;
  height: 40px !important;

  img {
    width: 100%;
    height: 100%;
    border-radius: 0.25rem !important;
    object-fit: cover;
  }
}

// modal
.modal-header,
.modal-footer {
  background: #f8f9fa;
}
