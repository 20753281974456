@import "./variable.scss";

.h-450 {
  height: 440px;
}

.widget-content {
  border: none;
  margin-bottom: 1rem;
  padding: 1rem 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  font-weight: bold;
  border-radius: $border-radius;
  box-shadow: 0 0.125rem 5rem rgba(0, 0, 0, 0.075) !important;
  cursor: pointer;

  .widget-numbers {
    font-size: 1.2rem;
    display: block;
  }

  &:hover {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    transition: box-shadow 400ms;
  }
}

.info-count {
  background-color: #fff;
  padding: 1.2rem;
  border: none !important;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.info-count-hover {
  cursor: pointer;

  &:hover {
    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.15) !important;
    transition: box-shadow 400ms;
  }
}

.count-active {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.avatar-lg {
  height: 4.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4.5rem;
  // color: $primary;
}

.bg-soft-primary {
  background-color: rgba(102, 88, 221, 0.25) !important;
  color: #6658dd !important;
  border-color: #6658dd !important;
}

.bg-soft-success {
  background-color: rgba(26, 188, 156, 0.25) !important;
  color: #1abc9c !important;
  border-color: #1abc9c !important;
}

.bg-soft-info {
  background-color: rgba(79, 198, 225, 0.25) !important;
  color: #0dcaf0 !important;
}

.bg-soft-warning {
  background-color: rgba(247, 184, 75, 0.25) !important;
  color: #ffc107 !important;
}

// .bg-grad {
//   background-image: linear-gradient(-180deg, #56b559, #088389) !important;
//   color: #ffffff;
// }

.bg-grad {
  background: $primary !important;
  color: $secondary !important;
}

.bg-night-fade {
  background-image: linear-gradient(160deg, #e9a100 0%, #d0a780 100%);
}

.bg-arielle-smile {
  background-image: linear-gradient(160deg, #0093e9 0%, #80d0c7 100%);
}

.bg-happy-green {
  background-image: linear-gradient(to bottom, #00b09b, #96c93d) !important;
}

.bg-premium-dark {
  background-image: linear-gradient(to right, #da8cff, #9a55ff);
}

.bg-expense {
  background-image: radial-gradient(
    circle 860px at 11.8% 33.5%,
    rgba(240, 30, 92, 1) 0%,
    rgba(244, 49, 74, 1) 30.5%,
    rgba(249, 75, 37, 1) 56.1%,
    rgba(250, 88, 19, 1) 75.6%,
    rgba(253, 102, 2, 1) 100.2%
  );
}
