@import "./variable.scss";

//Add Color Image
.image-area {
  position: relative;
  margin-bottom: 15px;
  // display: inline-block;
  // margin-bottom: 1rem;
  // margin-right: 1rem;
  // width: 180px;
  // height: 180px;
  // box-sizing: border-box;
  // overflow: hidden;
}

.image-area img {
  display: block;
  width: auto;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.remove-image {
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 2%;
  height: 35px;
  text-decoration: none;
  // background-image: linear-gradient(-180deg, #56b559, #088389) !important;
  color: #fff;
  transition: background 0.1s;
  display: inline;
  border: 0;
  border-radius: 0;
  border-bottom-left-radius: 10px;
}

.remove-image-2 {
  @extend .remove-image;
  right: 12px;
}

.remove-image svg {
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
}

.remove-image:hover,
.remove-image:active,
.remove-image:focus {
  background-image: linear-gradient(-180deg, #dc3545, #a41221) !important;
  box-shadow: none;
}
