$primary: #000;
$secondary: #fff;
$success: #666666;
$card-bg: #fafafa;
$text-muted-color: #6c757d;

//Sidebar Theme
// $sidebar-bg-color: linear-gradient(
//   76.3deg,
//   #0b1e35,
//   rgba(69, 103, 131, 1) 82.8%
// );

$sidebar-bg-color: #fff;
$sidebar-color: #6f6b6b !default;
$sidebar-collapsed-width: 80px !default;
$highlight-color: #55af3e !default;
$submenu-bg-color: #f7f8f9 !default;
$submenu-bg-color-collapsed: #fff !default;
$icon-bg-color: #f7f8f9 !default;
$table-striped-color-bg: #f8f9fa !default;

$border-radius: 0.25rem;
