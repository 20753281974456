@import "./variable.scss";
@import "/node_modules/bootstrap/scss/bootstrap";
// @import "/node_modules/react-pro-sidebar/dist/scss/styles.scss";
// @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

@import "./dashboard.scss";
@import "./product.scss";
@import "./custom.scss";
@import "./old_custom.scss";
@import "./react_select.scss";
@import "./auth.scss";
@import "./dropzone.scss";
@import "./print.scss";
@import "./pos.scss";
@import "./timeline.scss";
@import "./fonts";

body {
  font-family: "Poppins-Regular", "Pyidaungsu";
}

.fw-bold {
  font-weight: 500 !important;
}

.nav-link {
  font-size: 1rem;
  cursor: pointer;
  color: #000;

  &.active {
    border-bottom: 2px solid $primary;
  }
}

.cursor {
  cursor: pointer;
}

// $sidebar-bg-color: #0c1e35;
// $submenu-bg-color: rgba(255, 255, 255, 0.05);
// $submenu-bg-color-collapsed: rgba(#0c1e35, 0.95);
// $sidebar-color: #b3b8d4;
// $highlight-color: #dee2ec;
// $icon-bg-color: rgba(255, 255, 255, 0.05);
// $icon-size: 35px;

// .btn-toggle {
//   cursor: pointer;
//   width: 35px;
//   height: 35px;
//   background: #353535;
//   color: #fff;
//   text-align: center;
//   border-radius: 50%;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   font-size: 15px;
//   display: none;
// }

.header-wrapper {
  text-overflow: ellipsis;
  overflow: hidden;
  text-overflow: ellipsis;
  span {
    // color: #fff;
    margin-left: 10px;
    font-size: 18px;
    font-weight: bold;
    text-decoration: none;
  }
}

.sidebar-btn-wrapper {
  padding: 18px;
  background-color: $primary;

  .sidebar-logo {
    margin-left: 2px;
  }

  .sidebar-btn {
    transition: width 0.3s;
    text-decoration: none;
    margin: 0 auto;
    height: 35px;
    display: flex;
    align-items: center;
    //justify-content: center;
    text-overflow: ellipsis;
    overflow: hidden;
    span {
      color: #fff;
      overflow: hidden;
      margin-left: 10px;
      font-size: 18px;
      font-weight: bold;
      text-decoration: none;
    }
  }
}

.collapsed {
  .sidebar-btn {
    display: inline-block;
    line-height: 40px;
    padding: 0;
  }
}

.jewellery-filter {
  z-index: 3000;
}

.zindex-99 {
  // z-index: 0;
  position: absolute;
}

.social-bagdes {
  margin-top: 10px;
  img {
    margin: 5px;
  }
}

.block {
  display: flex;
  margin-bottom: 24px;
  font-size: 14px;
  color: #545454;
  > span {
    margin-left: 10px;
  }
}

.badge {
  // padding: 3px 6px;
  font-size: 0.65rem;
  letter-spacing: 1px;
  border-radius: 12px;
  &.red {
    color: #ffffff;
    background: #d63030;
  }
  &.gray {
    color: #ffffff;
    background: #777777;
  }
  &.yellow {
    color: #000000;
    background: #ffd331;
  }
}

.offcanvas-start {
  width: 800px !important;
}
