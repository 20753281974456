@import "./variable.scss";

@media print {
  footer {
    page-break-after: always;
  }
}

@page {
  size: auto;
  margin-top: 13mm;
}

@page :first {
  margin: 0;
}

.sale-receipt-print {
  font-family: Arial, Helvetica, sans-serif;
  padding-top: 30mm;
  width: 50%;
}

.receipt-header-print {
  width: 50%;

  .receipt-header {
    height: 29mm;
    // background-color: green;
  }
}

.receipt-header-print-line {
  width: 0.5in;
  margin-left: 0.5in;
}

.print-line {
  width: 0.5in;
  border-left: 1px dashed #212529;
  margin-left: 0.5in;
}

.print-label {
  font-size: 0.65rem;

  &-subtitle {
    font-size: 0.7rem;
  }

  &-xs {
    font-weight: bold;
    font-size: 7pt;
  }
}

.item-data-print {
  width: 100%;
  margin: 0.25rem 0.5rem 0.25rem 0.5rem;
  border-bottom: 1px solid #efefef;
  font-size: 0.8rem;
}

.print-thumnail {
  width: 100%;
  height: auto;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: $border-radius;
  }
}

.item-data-print span {
  width: 30%;
  display: inline-block;
}

.print-container {
  margin: 0.5in;
}

.print-invoice-container {
  margin: 1in;

  .print-text {
    font-size: 0.85rem;
  }

  .print-table {
    tr {
      height: 1rem;
    }

    th,
    td {
      font-size: 0.85rem;
      font-weight: bold;
      vertical-align: middle;
    }

    th {
      font-size: 0.85rem;
      font-weight: bold;
    }
  }
}

.print-second-container {
  margin-top: 0.5in;
}

.print-color-red {
  color: red !important;
}

.print-color-pink {
  color: pink !important;
}

.print-color-gold {
  color: #56b559 !important;
}

.print-color-purple {
  color: purple !important;
}

.print-labels-sticker {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .label-card-container {
    width: 100mm;
    height: 15mm;
    display: flex;
    padding-bottom: 3mm;
    margin-bottom: 3mm;

    .card-label {
      font-size: 6.25pt;
      font-weight: bolder;
      font-family: Arial, Helvetica, sans-serif;
      margin-bottom: 0;
    }
  }
}

.print-table {
  tr {
    height: 1rem;
  }

  th,
  td {
    font-size: 0.6rem;
    //border-color: #212529;
    font-weight: bold;
    vertical-align: middle;
  }

  th {
    font-size: 0.6rem;
    font-weight: bold;
  }
}

.print-text-lg {
  font-size: 0.85rem;
  font-weight: bold;
}

.print-text-price {
  font-size: 0.8rem;
  font-weight: bold;
}

.print-text {
  font-size: 0.65rem;
  font-weight: bold;
}

.print-receipt-table {
  tr {
    height: 1rem;
  }

  th,
  td {
    font-size: 8pt;
    font-weight: 700;
    // border-color: #212529;
    vertical-align: middle;
  }
}

.border-black {
  border-bottom: 1px solid #212529;
}

.print-labels {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-left: -0.08in;
  font-family: Arial, Helvetica, sans-serif;

  .label-card-for-32-25 {
    width: 1.125in;
    height: 0.9in;
    // border: 1px dashed rgb(122, 122, 122);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    .card-label {
      text-align: center;
      font-size: 9pt;
      font-weight: bolder;
    }
  }
}

.default-margin {
  margin: 0.5in !important;
}

.margin-for-receipt {
  margin: 0.3in 0.5in !important;
}

.print-margin-top {
  padding-top: 30mm;
}

.print-table {
  tr {
    height: 1rem;
  }

  th,
  td {
    font-size: 0.65rem;
    //border-color: #212529;
    vertical-align: middle;
  }

  th {
    font-size: 0.6rem;
    font-weight: bold;
  }
}
