$primary: #55af3e;
$success: #068289;
$body-bg: #fff;

.navbar-brand {
  color: #55af3e !important;
  font-weight: bold;
}

.form-title {
  color: #55af3e !important;
  font-weight: bold;
}

.banner {
  height: 653px;
  background: url("../images/header-update.png") right top no-repeat;
  width: 100%;
}

.banner-container {
  padding-top: 3rem;
}

.fs-hero {
  font-size: 36px;
}

@media (max-width: 1024px) {
  .banner {
    background: url("../images/header-update-ipad.png") right 0 no-repeat;
    background-size: 84%;
  }

  .banner-container {
    padding-top: 4rem;
  }
}

@media (min-width: 1000px) {
  .sec-title {
    width: 320px;
  }

  .sec-body {
    width: 424px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .banner {
    height: 520px;
  }
}

ul.navbar-nav {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  float: right;
  display: block;
}

.side-nav .nav-link {
  color: #000 !important;
}

.home-nav li.nav-item {
  float: left;
}

.home-nav li.nav-item.active {
  font-weight: bold;
}

.bg-nav {
  background-image: linear-gradient(
    to right,
    #eeeeee 0%,
    #ffffff 51%,
    #eeeeee 100%
  );
  border-bottom: 1px dashed #ddd !important;
}

.side-nav-border {
  border-right: 1px dashed #ddd;
}

.side-nav a.inactive:hover {
  background-image: linear-gradient(
    to right,
    #eeeeee 0%,
    #ffffff 51%,
    #eeeeee 100%
  );
  transition: 0.5s;
  background-size: 200% auto;
  color: #55af3e !important;
}

.side-nav a.active {
  transition: 0.5s;
  background-size: 200% auto;
  background-image: linear-gradient(
    to right,
    #56b559 0%,
    #088389 51%,
    #56b559 100%
  );
  color: #fff !important;
}

.side-nav a.active:hover {
  background-position: right center;
  color: #000;
}

.home-nav li.nav-item a.nav-link {
  font-size: 1.1rem;
  text-decoration: none;
  color: #fff;
  padding: 1rem;
}

.home-nav li.nav-item a.nav-link:hover {
  color: #fff;
}

// .btn-grad {
//   background-image: linear-gradient(
//     to right,
//     #56b559 0%,
//     #088389 51%,
//     #56b559 100%
//   );
// }

// .btn-grad {
//   text-align: center;
//   transition: 0.5s;
//   background-size: 200% auto;
//   color: white;
//   box-shadow: 0 0 20px #eee;
//   display: block;
// }

// .btn-grad:hover {
//   background-position: right center;
//   color: #fff;
//   text-decoration: none;
// }

// .btn-grad.btn-sm {
//   padding: 0.25rem 0.45rem;
// }

.btn-sm svg {
  transform: scale(0.7);
}

.text-success {
  color: #068289 !important;
}

.text-gold {
  color: #dfad2b !important;
}

.section-1 {
  min-height: 320px;
}

.section-2 {
  height: 520px;
  width: 100%;
  background-image: linear-gradient(180deg, #56b559, #088389);
}

.p-sec-1 {
  padding-left: 4rem;
}

.p-sec-3 {
  padding-left: 4rem;
}

.form-container {
  background-color: #f0f0f0;
  min-height: 456px;
}

.copy-right {
  font-size: 0.8rem;
}

.hr-style {
  border-top: 1px dashed #ddd;
}

.nav-link {
  padding: 0.75rem 1rem;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .nav-link {
    border-bottom: 1px dashed #ddd;
  }
}

.nav-link:last-child {
  border-bottom: none;
}

.copy-right a {
  color: #a5a5a5;
  text-decoration: none;
}

.copy-right a:hover {
  color: #a5a5a5;
  text-decoration: underline;
}

.hero-text {
  padding-top: 4rem;
  clear: both;
  text-align: right;
}

.center-container {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #282c34;
}

.signin-container {
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  min-width: 50vw;
  background: var(--bs-dark);
}

.form-signin {
  width: 100%;
  max-width: 400px;
  padding: 2.5rem 3rem 1.5rem;
  margin: auto;
  background: #000 !important;
  border-radius: 0.4rem;
}

.form-signin .checkbox {
  font-weight: 400;
}

.form-signin .form-floating:focus-within {
  z-index: 2;
}

.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

// .nav-link svg {
//   margin-right: 0.5rem;
//   vertical-align: middle;
//   width: 18px;
// }

// .nav-link.active svg {
//   fill: #ffffff;
// }

// .btn-grad svg {
//   fill: #ffffff;
// }

// .btn-danger svg {
//   fill: #ffffff;
// }

// .btn-dark svg {
//   fill: #ffffff;
// }

// .btn-success svg {
//   fill: #ffffff;
// }

// .icon svg {
//   vertical-align: middle;
//   width: 24px;
//   fill: #56b559;
// }

// .nav-link.active {
//   font-weight: bold;
// }

// .nav-link svg {
//   fill: #55af3e;
// }

// .menu-nav {
//   border-bottom: 1px dashed #ddd;
// }

// .card {
//   border: 1px dashed #ddd;
// }

// h6.card-title {
//   font-size: 0.85rem;
//   color: #088389;
//   line-height: 1.125rem;
// }

// .card-title span {
//   color: #5a5a5a;
// }

// .card-title {
//   cursor: pointer;
//   background-color: #f8f9fa;
//   margin: 0;
// }

// .offcanvas-header {
//   padding: 1.35rem 1rem;
// }

// .collapse-content {
//   font-size: 0.85rem;
// }

// .list-group-item {
//   padding: 0.5rem 0;
//   border: none;
//   line-height: 2.5rem;
// }

// .list-group-item span {
//   width: 40%;
//   display: inline-block;
// }

// .list-group-item div {
//   padding: 0rem 0.25rem;
// }

// .list-group-item div:nth-of-type(even) {
//   border-bottom: 1px solid #eee;
//   border-top: 1px solid #eee;
// }

// .item-table {
//   min-width: 640px;
//   font-size: 0.8rem;
// }

// .item-table td {
//   width: 25%;
// }

// .collapse-button {
//   display: block;
//   width: 100%;
// }

// .collapse-content.collapsed {
//   display: none;
// }

// .collapsed-content.expanded {
//   display: block;
// }

// .card-title span {
//   font-size: 0.8rem;
// }

// .form-check-input:checked {
//   background-color: #56b559;
//   border-color: #088389;
// }

// .form-switch .form-check-input {
//   background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2356b559'/%3e%3c/svg%3e");
// }

// .form-switch .form-check-input:focus {
//   background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23088389'/%3e%3c/svg%3e");
// }

// .inventory-form {
//   // background-color: green;
// }

// .inventory-form .form-label,
// .inventory-form .form-check-label,
// .inventory-form .form-select {
//   font-size: 0.875rem;
// }

// .form-control,
// .input-group-text,
// .form-select {
//   border: 1px solid #e9edef;
// }

// .text-xs {
//   font-size: 0.7rem;
// }

// .border-start-primary {
//   border-left: 0.25rem solid #0d6efd !important;
// }

// .border-start-info {
//   border-left: 0.25rem solid #0dcaf0 !important;
// }

// .border-start-warning {
//   border-left: 0.25rem solid #ffc107 !important;
// }

// .border-start-success {
//   border-left: 0.25rem solid #068289 !important;
// }

// .small {
//   font-size: 80%;
// }

// .btn-secondary {
//   background-color: #e9ecef;
//   border: none;
// }

// .dropzone {
//   flex: 1;
//   display: flex;
//   //flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   width: 20%;
//   height: 3rem;
//   padding: 24px;
//   cursor: pointer;
//   border-radius: 10px;
//   // border-width: 1px;
//   // border-radius: 6px;
//   // border-color: #ddd;
//   // border-style: dashed;
//   background-image: linear-gradient(
//     to right,
//     #56b559 0%,
//     #088389 51%,
//     #56b559 100%
//   );
//   color: #fff;
//   outline: none;
//   transition: border 0.24s ease-in-out;
// }

// .thumbs-container {
//   display: flex;
//   flex-direction: row;
//   flex-wrap: wrap;
//   margin-top: 6px;
// }

// .thumb {
//   display: inline-flex;
//   border-radius: 2px;
//   border: 1px solid #eaeaea;
//   margin-bottom: 8px;
//   margin-right: 8px;
//   width: 100px;
//   height: 100px;
//   padding: 4px;
//   box-sizing: border-box;
// }

// .thumb-inner {
//   display: flex;
//   min-width: 0;
//   overflow: hidden;
// }

// .thumb-img {
//   display: block;
//   width: auto;
//   height: 100%;
// }

// .image-area {
//   position: relative;
//   display: inline-block;
//   border-radius: 2px;
//   border: 4px solid #fff;
//   margin-bottom: 6px;
//   margin-right: 6px;
//   margin-top: 6px;
//   width: 180px;
//   height: 180px;
//   box-sizing: border-box;
//   overflow: hidden;
//   outline: 1px solid #eaeaea;
//   cursor: pointer;
// }

// .image-area {
//   position: relative;
//   display: inline-block;
//   border-radius: 2px;
//   //border: 4px solid #fff;
//   margin-bottom: 6px;
//   margin-right: 6px;
//   margin-top: 6px;
//   width: 180px;
//   height: 180px;
//   box-sizing: border-box;
//   overflow: hidden;
//   outline: 1px solid #eaeaea;
//   cursor: pointer;
// }

// .image-area-active {
//   position: relative;
//   display: inline-block;
//   border-radius: 2px;
//   border: 5px solid $primary;
//   margin-bottom: 6px;
//   margin-right: 6px;
//   margin-top: 6px;
//   width: 180px;
//   height: 180px;
//   box-sizing: border-box;
//   overflow: hidden;
//   outline: 1px solid #eaeaea;
//   cursor: pointer;
// }

// .image-area img {
//   display: block;
//   width: auto;
//   height: 100%;
//   margin: 0 auto;
// }

// .remove-image {
//   position: absolute;
//   top: 0px;
//   right: 0px;
//   padding: 2%;
//   text-decoration: none;
//   font: 700 21px/20px sans-serif;
//   background-image: linear-gradient(
//     45deg,
//     #56b559 0%,
//     #088389 51%,
//     #56b559 100%
//   );
//   color: #fff;
//   transition: background 0.1s;
//   border-radius: 0 0 0 10px !important;
//   display: inline;
//   -webkit-filter: drop-shadow(0.35rem 0.35rem 0.4rem rgba(0, 0, 0, 0.2));
//   filter: drop-shadow(0.35rem 0.35rem 0.4rem rgba(0, 0, 0, 0.2));
//   border: 0;
// }

// .remove-image svg {
//   -webkit-filter: brightness(0) invert(1);
//   filter: brightness(0) invert(1);
// }

// .remove-image:hover,
// .remove-image:active,
// .remove-image:focus {
//   background-image: linear-gradient(
//     45deg,
//     #e52d27 0%,
//     #b31217 51%,
//     #e52d27 100%
//   );
//   -webkit-filter: drop-shadow(0.35rem 0.35rem 0.4rem rgba(0, 0, 0, 0.2));
//   filter: drop-shadow(0.35rem 0.35rem 0.4rem rgba(0, 0, 0, 0.2));
//   -webkit-box-shadow: none;
//   box-shadow: none;
// }

// .btn-round {
//   width: 48px;
//   height: 48px;
// }

// .user-list,
// .product-list,
// .report {
//   font-size: 0.9rem;
// }

// .border {
//   border: 1px dashed #e9edef !important;
// }

// .border-top {
//   border-top: 1px dashed #e9edef !important;
// }

// .border-end {
//   border-right: 1px dashed #e9edef !important;
// }

// .border-bottom {
//   border-bottom: 1px dashed #e9edef !important;
// }

// .border-start {
//   border-left: 1px dashed #e9edef !important;
// }

// .btn-link svg {
//   -webkit-filter: invert(34%) sepia(17%) saturate(5846%) hue-rotate(154deg)
//     brightness(94%) contrast(95%);
//   filter: invert(34%) sepia(17%) saturate(5846%) hue-rotate(154deg)
//     brightness(94%) contrast(95%);
// }

// .btn-light {
//   background-color: transparent;
//   border-color: transparent;
// }

// .btn-light svg {
//   -webkit-filter: invert(47%) sepia(7%) saturate(574%) hue-rotate(167deg)
//     brightness(93%) contrast(87%);
//   filter: invert(47%) sepia(7%) saturate(574%) hue-rotate(167deg)
//     brightness(93%) contrast(87%);
// }

// .p-name {
//   height: 56px;
// }

// .img-container {
//   position: relative;
//   width: 100%;
// }

// .image {
//   opacity: 1;
//   display: block;
//   width: 100%;
//   // height: auto;
//   transition: 0.5s ease;
//   backface-visibility: hidden;
// }

// .middle {
//   transition: 0.5s ease;
//   opacity: 0;
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   -ms-transform: translate(-50%, -50%);
//   text-align: center;
// }

// .img-container:hover .image {
//   opacity: 0.5;
// }

// .img-container:hover .middle {
//   opacity: 1;
// }

// .action-icon {
//   background-image: radial-gradient(circle at top left, #fff, #eee);
//   border: 2px solid #068289;
//   color: white;
//   font-size: 1rem;
//   padding: 0.75rem 0;
//   border-radius: 50%;
//   width: 52px;
//   height: 52px;
//   display: block;
// }

// .action-icon:hover {
//   background-image: radial-gradient(circle at top left, #088389, #56b559);
//   border: 2px solid #56b559;
// }

// .action-icon:hover svg {
//   filter: invert(94%) sepia(0%) saturate(7489%) hue-rotate(71deg)
//     brightness(112%) contrast(101%);
//   -webkit-filter: invert(94%) sepia(0%) saturate(7489%) hue-rotate(71deg)
//     brightness(112%) contrast(101%);
// }

// .text-small {
//   font-size: 0.75rem !important;
// }
