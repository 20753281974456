@import './variable.scss';

.react-select-container {
  font-size: 0.875rem;

  .react-select__control {
    background-color: #ffffff;
    border-color: #e9edef;
    border-radius: $border-radius;
    // border-top-left-radius: $border-radius;
    // border-bottom-left-radius: $border-radius;
    transition: none;
    height: 3rem;

    &:hover {
      border-color: $primary;
    }
  }

  .react-select__indicator {
    svg {
      height: 18px;
      width: 26px;
      fill: #212529;
    }
  }

  .react-select__menu {
    background-color: #ffffff;
  }

  .react-select__indicator-separator {
    background-color: #e9edef;
  }

  .react-select__placeholder {
    color: #212529;
  }

  .react-select__multi-value__remove:hover {
    background-color: inherit;
  }

  .react-select__indicators {
    svg {
      fill: #757b80;
      width: 12px;
      height: 12px;
      margin: 2px;
    }
  }

  .react-select__multi-value__remove {
    svg {
      fill: #757b80;
      width: 10px;
      height: 10px;
      margin: 2px;
    }
    svg:hover {
      fill: black;
    }
  }
}

.react-select-container-half-radius {
  font-size: 0.875rem;

  .react-select__control {
    background-color: #ffffff;
    border-color: #e9edef;
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
    transition: none;
    height: 3rem;

    &:hover {
      border-color: $primary;
    }
  }

  .react-select__indicator {
    svg {
      height: 18px;
      width: 26px;
      fill: #212529;
    }
  }

  .react-select__menu {
    background-color: #ffffff;
  }

  .react-select__indicator-separator {
    background-color: #e9edef;
  }

  .react-select__placeholder {
    color: #212529;
  }

  .react-select__multi-value__remove:hover {
    background-color: inherit;
  }

  .react-select__indicators {
    svg {
      fill: #757b80;
      width: 12px;
      height: 12px;
      margin: 2px;
    }
  }

  .react-select__multi-value__remove {
    svg {
      fill: #757b80;
      width: 10px;
      height: 10px;
      margin: 2px;
    }
    svg:hover {
      fill: black;
    }
  }
}
