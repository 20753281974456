@import "./variable.scss";

.product-card {
  // box-shadow: 0 0.125rem 5rem rgba(0, 0, 0, 0.075) !important;
  background-color: #fff;
  border-radius: $border-radius;
  margin-bottom: 1.5rem;
  display: flex;
  height: 260px;
  cursor: pointer;

  &-image {
    width: 40%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-top-left-radius: $border-radius;
      border-bottom-left-radius: $border-radius;
    }
  }

  &-info {
    width: 60%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &-small-image {
    width: 35px;
    height: 35px;
    margin-right: 10px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: $border-radius;
    }
  }

  &:hover {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    transition: box-shadow 400ms;
  }
}

.product-card-list-view {
  // box-shadow: 0 0.125rem 5rem rgba(0, 0, 0, 0.075) !important;
  background-color: $card-bg;
  border-radius: $border-radius;
  margin-bottom: 1.5rem;
  display: flex;
  height: 200px;
  cursor: pointer;

  .product-card-image {
    width: 20%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-top-left-radius: $border-radius;
      border-bottom-left-radius: $border-radius;
    }
  }

  .product-card-info {
    width: 80%;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
  }

  .product-card-small-image {
    width: 50px;
    height: 50px;
    margin-right: 10px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: $border-radius;
    }
  }

  &:hover {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    transition: box-shadow 400ms;
  }
}

.product-card-small-image {
  width: 50px;
  height: 50px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: $border-radius;
  }
}

.detail {
  &-image {
    width: 100%;
    img {
      border-radius: $border-radius;
      width: 100%;
    }
  }
}

.float-btns {
  display: flex;
  width: 100%;
  position: fixed;
  left: 1rem;
  bottom: 1rem;
  z-index: 1000;
}
