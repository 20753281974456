@import "./variable.scss";

.pos-item {
  background-color: #fff;
  border-radius: $border-radius;
  cursor: pointer;
  height: 94%;

  &:hover {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    transition: box-shadow 400ms;
  }

  .pos-item-image {
    width: 100%;
    height: 12.3rem;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;
    }
  }
}
.pos-item-view {
  overflow: scroll;
}

// .pos-cart-scroll-area{
//   overflow: auto;
//   height: 90vh;
// }

.pos-cart {
  background-color: #fff;
  height: 91vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: scroll;
  width: -webkit-fill-available;
  width: -moz-available;
  // position: sticky;
  // top: 9vh;

  .pos-sale-btn {
    border-radius: 0;
    // background-image: linear-gradient(-180deg, #56b559, #088389) !important;
    background-color: $primary;
    height: 4rem;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
    position: sticky;
    bottom: 0px;
    z-index: 10;
    //  position: fixed;
    // bottom: 0;
    // right: 0;
    // width: 40%;
    padding: 1.5rem;
    color: white;
    font-weight: bold;

    &:hover {
      background-color: $primary;
      // background-image: linear-gradient(-120deg, #56b559, #088389) !important;
    }
  }
}

.cart-item-image {
  img {
    width: 100%;
    height: 155px;
    object-fit: cover;
    border-radius: $border-radius;
  }
}

.detail-item-image {
  img {
    width: 100%;
    height: 240px;
    object-fit: cover;
    border-radius: $border-radius;
  }
}

.receit-item-image {
  img {
    width: 90px;
    height: 90px;
    object-fit: cover;
    border-radius: $border-radius;
  }
}
