@import './variable.scss';

.login-container {
  height: 100vh;

  &-image {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $primary;
    // background-image: linear-gradient(
    //   76.3deg,
    //   #0b1e35,
    //   rgba(69, 103, 131, 1) 82.8%
    // );
    // opacity: 0.5;
    // background: url('../../assets/images/login-image.jpeg') no-repeat center
    //   fixed;
    // background-size: cover;
    // background-attachment: scroll;
    // mask-image: linear-gradient(
    //   to bottom,
    //   rgba(0, 0, 0, 1) 50%,
    //   transparent 100%
    // );

    // &-child {
    //   background-image: url('../../assets/images/login-image.jpeg');
    //   width: 100%;
    //   height: 100%;
    //   opacity: 0.5;
    //   background-image: linear-gradient(
    //     76.3deg,
    //     #0b1e35,
    //     rgba(69, 103, 131, 1) 82.8%
    //   );
    //  }
  }

  &-field {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
